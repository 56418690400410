<script>
    // import {mapState} from 'vuex'
    import Card from '@/components/Card'

    export default {
        name: 'Login',

        components: {Card},

        data() {
            return {
                isLoading: false,
                form: {
                    username: null,
                    password: null,
                },
                dataLanguage: 'de_AT',
                interfaceLanguage: 'de_AT',
            }
        },

        methods: {
            submit() {
                this.$root.post('login', {
                    username: this.form.username,
                    password: this.form.password,
                }).then(response => {
                    if ('jwt' in response) {
                        this.$root.findChild(this.$root.findParent(this, 'home'), 'NavBar', $navBar => {
                            $navBar.user = response.user
                        })

                        this.$root.settings('locale', this.dataLanguage)

                        this.$root.Token.set(response.jwt)
                        this.$root.Storage.set('user', response.user)
                        this.$router.push('/sub-brands')

                        this.$root.findParent(this, 'home').buildMenu()
                        this.$root.Storage.removeByWildcard('-search')
                    }
                }).catch(() => {
                    this.$root.findParent(this, 'home').notify(this.$t('Login is failed...'), 'danger', 'account-off-outline')
                })
            },

            checkIfCountryAllowed: function (countryLanguages) {
                let found = false;
                countryLanguages.forEach(countryLanguage => {
                    if (typeof this.$i18n.messages[countryLanguage.LocaleCode] !== 'undefined') {
                        found = true;
                        return true;
                    }
                });
                return found;
            },

            checkIfLanguageAllowed: function (lang) {
                if (typeof this.$i18n.messages[lang.LocaleCode] !== 'undefined') {
                    return true;
                }
                return false;
            },
        },

        mounted() {
            this.$root.Storage.remove('user')
            this.$root.Storage.remove('settings')
            this.$root.Token.reset()
        },

        watch: {
            interfaceLanguage: function (newValue) {
                if (typeof this.$i18n.messages[newValue] !== 'undefined') {
                    this.$i18n.locale = newValue;
                    this.$root.settings('interfaceLanguage', newValue)
                }
            },
        },
    }
</script>

<template>
    <card>
        <div class="login-logo">
            <img src="/data-sources/logo/logo-red-green.png" alt="">
        </div>
        <form @submit.prevent="submit" method="POST" class="login-form">
            <b-field :label="$t('Username')">
                <b-input name="username" required autofocus v-model="form.username"/>
            </b-field>

            <b-field :label="$t('Password')">
                <b-input type="password" name="password" required v-model="form.password"/>
            </b-field>

            <b-field :label="$t('Data language')">
                <b-select :placeholder="$t('Choose the language')" v-model="dataLanguage">
                    <optgroup v-for="country in $root.countries" :key="country.Id"
                              :label="country.CountryI18ns[0].Name">
                        <option v-for="(lang, lindex) in country.Languages" :key="lindex"
                                :value="lang.LocaleCode">
                            {{ lang.LanguageI18ns[0].Name }}
                        </option>
                    </optgroup>
                </b-select>
            </b-field>

            <b-field :label="$t('Interface language')">
                <b-select :placeholder="$t('Choose the language')" v-model="interfaceLanguage">
                    <template v-for="country in $root.countries">
                        <optgroup :key="country.Id" :label="country.CountryI18ns[0].Name" v-if="checkIfCountryAllowed(country.Languages)">
                            <template v-for="(lang, lindex) in country.Languages">
                                <option :key="lindex" :value="lang.LocaleCode"
                                        v-if="checkIfLanguageAllowed(lang)">
                                    {{ lang.LanguageI18ns[0].Name }}
                                </option>
                            </template>
                        </optgroup>
                    </template>
                </b-select>
            </b-field>

            <div class="control">
                <button type="submit" class="button is-fullwidth is-green"
                        :class="{ button: true, 'is-fullwidth': true, 'is-green': true, 'is-loading': this.isLoading }">
                    {{ $t('Login') }}
                </button>
            </div>
        </form>
    </card>
</template>
