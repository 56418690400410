<script>
    export default {
        mounted() {
            this.$store.commit('fullPage', true)
        },

        beforeDestroy() {
            this.$store.commit('fullPage', false)
        },
    }
</script>

<template>
    <section class="section hero is-fullheight is-error-section">
        <div class="hero-body">
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-two-fifths">
                        <router-view/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
